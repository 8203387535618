import React from 'react'
import classNames from 'classnames'

import Reveal from 'Reveal'

import MediaBlockGalleryImage from './MediaBlockGalleryImage'

const MediaBlockGallery = ({ images, columnSpan = 1, lazyload }) => (
  <div className='c-media-block c-media-block--gallery'>
    <div className='o-container'>
      <div className={`c-media-block-gallery c-media-block-gallery--col-${columnSpan}`}>
        {
          images && images.map(({ image, url, iframe, hideOnMobile, isGIF }, index) => {
            return (
              <Reveal key={index} fraction={0.5} duration={800}>
                <div
                  className={classNames('c-media-block-gallery__img', `c-media-block-gallery__img--${images.length}`, {
                    'u-hide-completely-until@md': hideOnMobile
                  })}
                >
                  <MediaBlockGalleryImage
                    image={image}
                    url={url}
                    iframe={iframe}
                    isGIF={isGIF}
                    lazyload={lazyload}
                    sizes={{
                      md: `calc((100vw - 32px) / ${images.length})`,
                      xl: `calc(1312px / ${images.length})`,
                      default: 'calc(100vw - 64px)'
                    }}
                  />
                </div>
              </Reveal>
            )
          })
        }
      </div>
    </div>
  </div>
)

export default MediaBlockGallery
