import React from 'react'
import classNames from 'classnames'

import Reveal from 'Reveal'

import MediaBlockGalleryImage from './MediaBlockGalleryImage'

const MediaBlockOneUp = ({ image, mobileImage, url, iframe, isGIF, lazyload, hideOnMobile, columnSpan }) => {
  return (
    <div className={classNames('c-media-block c-media-block--one-up', {
      'u-hide-completely-until@md': hideOnMobile
    })}
    >
      <Reveal fraction={0.25} duration={800}>
        <div
          className={classNames('u-img-full u-mar-b-none u-mar-auto', {
            'u-width-1/3@md': columnSpan === '1-3',
            'u-width-2/3@md': columnSpan === '2-3'
          })}
        >
          <MediaBlockGalleryImage
            image={image}
            mobileImage={mobileImage}
            url={url}
            iframe={iframe}
            isGIF={isGIF}
            lazyload={lazyload}
            sizes={{
              md: '100vw',
              xl: '1312px'
            }}
          />
        </div>
      </Reveal>
    </div>
  )
}

export default MediaBlockOneUp
