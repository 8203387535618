import React from 'react'
import classNames from 'classnames'

import Reveal from 'Reveal'

const MediaBlockVideo = ({ videoType, url, hideOnMobile }) => (
  <div className={classNames('c-media-block c-media-block--video', {
    'u-hide-completely-until@md': hideOnMobile
  })}
  >
    <div className='o-container--sm'>
      <Reveal fraction={0.25} duration={600}>
        {/* eslint-disable */}
        <div className={`c-media-block__video c-media-block__video--${videoType}`}>
          { videoType === 'embed' ? <iframe
            src={url}
            frameBorder='0'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          /> : <video
            src={url}
            preload='metadata'
            playsInline
            controls
          /> }
        {/* eslint-enable */}
        </div>
      </Reveal>
    </div>
  </div>
)

export default MediaBlockVideo
