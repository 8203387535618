import React from 'react'
import classNames from 'classnames'

import Section from 'Section'
import BackToTop from 'BackToTop'

import ProjectHero from './ProjectHero'
import ProjectBody from './ProjectBody'
import ProjectMedia from './ProjectMedia'
import ProjectCredits from './ProjectCredits'
import ProjectNavigation from './ProjectNavigation'

const Project = ({ client, projectTitle, description, featureImage, media, credits, body, props, lazyload, className, previousProject, nextProject, heroReveal }) => (
  <article
    className={classNames('c-project', {
      [className]: className
    })}
    id='top'
  >
    <ProjectHero
      image={featureImage}
      content={description}
      lazyload={false}
      reveal={heroReveal}
    />
    <div className='c-project-main'>
      <Section className='c-page-section--project'>
        <ProjectBody
          content={body}
          title={projectTitle}
        />
      </Section>
      {
        media &&
          <ProjectMedia
            media={media}
            lazyload={lazyload}
          />
      }
      {
        (credits && credits.length > 0) && <ProjectCredits credits={credits} />
      }
      <BackToTop />
      <ProjectNavigation
        previousProject={previousProject}
        nextProject={nextProject}
      />
    </div>
  </article>
)

export default Project
