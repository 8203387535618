import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash.get'

import Layout from 'Layout'
import Project from 'Project'
import SEO from 'SEO'

const ProjectTemplate = ({ data, location }) => {
  const {
    html,
    frontmatter,
    excerpt,
    fields
  } = data.markdownRemark

  /* Find current Project in AllProjects array */
  const { nodes: allProjects } = data.allProjects
  const currentProjectIndex = allProjects.findIndex(project =>
    project.fields.slug === fields.slug
  )

  /* Use that to find the previous & next projects */
  const nextProject = allProjects[currentProjectIndex + 1]
  const previousProject = allProjects[currentProjectIndex - 1]

  return (
    <Layout>
      <SEO
        title={frontmatter.projectTitle}
        description={excerpt}
        slug={fields.slug}
        image={frontmatter.featureImage.desktop}
      />
      <Project
        body={html}
        previousProject={previousProject}
        nextProject={nextProject}
        heroReveal={get(location, 'state.reveal', false)}
        {...frontmatter}
      />
    </Layout>
  )
}

ProjectTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object
}

export default ProjectTemplate

export const query = graphql`
  query Project($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        client
        projectTitle
        description
        featureImage {
          mobile
          desktop
          url
          isGIF
        }
        media {
          image
          mobileImage
          hideOnMobile
          images {
            image
            url
            iframe
            hideOnMobile
            isGIF
          }
          isGIF
          columnSpan
          type
          url
          iframe
          videoType
        }
        credits {
          name
          role
        }
      }
    }
    ...AllLiveProjectsFragment
  }
`
