import React from 'react'

import Figure from 'Figure'
import Image from 'Image'
import LazyVideo from 'LazyVideo'

const MediaBlockGalleryImage = ({ image, mobileImage, url, iframe, isGIF, lazyload, sizes }) => {
  if (url || iframe) {
    /**
     * Create a poster image using the Image components
     * static makeUrl method.
     */
    const poster = Image.makeUrl({
      src: image,
      width: 200,
      quality: 'lightest',
      blur: 100
    })

    return (
      <LazyVideo
        iframe={iframe}
        src={url}
        poster={poster}
        className='c-lazy-image__main--gallery-video'
      />
    )
  }

  if (isGIF) {
    return (
      <div className='u-img-full'>
        <img
          src={image}
          alt=''
        />
      </div>
    )
  }

  return (
    <Figure
      image={image}
      mobileImage={mobileImage}
      className='u-img-full u-mar-b-none'
      lazyload={lazyload}
      width={1312}
      sizes={sizes}
    />
  )
}

export default MediaBlockGalleryImage
