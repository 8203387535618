import React from 'react'

import MediaBlock from 'MediaBlock'

const ProjectMedia = ({ media, lazyload }) => (
  media.map((item, index) =>
    <MediaBlock
      lazyload={lazyload}
      key={index}
      {...item}
    />
  )
)

export default ProjectMedia
