import React from 'react'
import classNames from 'classnames'

// Utils
import cssBreakpoints from 'utils/cssBreakpoints'

import Figure from 'Figure'
import Image from 'Image'
import LazyVideo from 'LazyVideo'

const SliderItemImage = ({ image, mobileImage, url, isGIF, className, lazyload, title }) => {
  if (url) {
    /**
     * Create a poster image using the Image components
     * static makeUrl method.
     */
    const poster = Image.makeUrl({
      src: image,
      width: 200,
      quality: 'lightest',
      blur: 100
    })

    return (
      <LazyVideo
        src={url}
        poster={poster}
        className='c-lazy-image__main--gallery-video'
        wrapperClassName={classNames('o-fill-parent', className)}
        title={title}
      />
    )
  }

  if (isGIF) {
    if (mobileImage) {
      return (
        <div className={classNames('o-fill-parent', className)}>
          <picture>
            <source
              media={`(max-width: ${(cssBreakpoints.md / 16) - 0.01}em)`}
              srcSet={mobileImage}
            />
            <img
              src={image}
              alt={title}
            />
          </picture>
        </div>
      )
    }

    return (
      <div className={classNames('o-fill-parent', className)}>
        <img
          src={image}
          alt=''
        />
      </div>
    )
  }

  return (
    <Figure
      image={image}
      mobileImage={mobileImage}
      className={className}
      wrapperClassName='o-fill-parent'
      width={1440}
      sizes={{
        default: '100vw'
      }}
      lazyload={lazyload}
      alt={title}
    />
  )
}

export default SliderItemImage
