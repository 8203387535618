import React, { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'

const LazyVideo = ({ src, iframe = false, className, wrapperClassName, poster, title, ...props }) => {
  /**
   * Set up a ref for the <video> element, so we can
   * trigger events on it. The react-interaction-observation
   * observer default `ref` does not work for this.
   **/
  const video = useRef(null)

  // Set up the InView hook
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true
  })

  // Listen for updates to the inView state
  /* eslint-disable */
  useEffect(() => {
    if (inView) {
      if (iframe) {
        let src = video.current.dataset.src
        let autoplay = false

        if (src.includes('?')) {
          // get after the `?`
          const srcParams = new URLSearchParams(src.split('?')[1])

          if (!srcParams.get('api')) {
            src += '&api=1'
          }

          if (srcParams.get('autoplay')) {
            autoplay = srcParams.get('autoplay')
          }
        } else {
          src += '?api=1'
        }

        // if it's an iframe, then change the data-src to src
        video.current.src = src

        if (autoplay) {
          video.current.contentWindow.postMessage(JSON.stringify({
            method: 'play'
          }), '*')
        }
      } else {
        // if it's a video, load then play the video
        video.current.load()

        if (video.current.paused) {
          video.current.play()
        }
      }
    }
  }, [inView])
  /* eslint-enable */

  return (
    <div
      ref={ref}
      className={classNames('c-lazy-image u-img-full', wrapperClassName)}
    >
      <img
        src={poster}
        className='c-lazy-image__preview'
        alt={title}
      />
      {/* eslint-disable */}
      { iframe ? <iframe
        data-src={iframe}
        frameBorder='0'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        className={classNames('c-lazy-image__main', className)}
        ref={video}
        allowFullScreen
      /> : <video
        autoPlay
        loop
        playsInline
        muted
        ref={video}
        className={classNames('c-lazy-image__main', className)}
        {...props}
      >
        {
          inView
            ? <source src={src} />
            : <source data-src={src} />
        }
      </video>
      }
    {/* eslint-enable */}
    </div>
  )
}

export default LazyVideo
