import React from 'react'

import { HTMLContent } from 'Content'
import Reveal from 'Reveal'
import SliderItemImage from 'Slider/SliderItemImage'

import toHTML from 'utils/toHTML'

export const ProjectHeroTemplate = ({ image, content, lazyload }) => (
  <div className='c-hero c-project-hero u-ta-c'>
    <div className='c-hero-main u-img-splash'>
      <SliderItemImage
        image={image.desktop}
        mobileImage={image.mobile}
        url={image.url}
        isGIF={image.isGIF}
        className='c-project-hero__img'
        lazyload={lazyload}
      />
    </div>
    <span className='c-hero-footer' aria-hidden>
      <span className='c-hero-footer__content c-project-hero__description' aria-hidden>
        <HTMLContent content={toHTML(content)} />
      </span>
    </span>
  </div>
)

const ProjectHero = ({ reveal, ...props }) => {
  // if (reveal) {
  return (
    <Reveal>
      <ProjectHeroTemplate {...props} />
    </Reveal>
  )
  // }

  // return (
  //   <ProjectHeroTemplate {...props} />
  // )
}

export default ProjectHero
