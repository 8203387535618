import React from 'react'

const ProjectHeader = ({ title }) => {
  return (
    <header className='c-project-header'>
      <h1 className='e-h2 c-project-header__title'>{title}</h1>
    </header>
  )
}

export default ProjectHeader
