import React from 'react'

import Gallery from './MediaBlockGallery'
import OneUp from './MediaBlockOneUp'
import FullWidth from './MediaBlockFullWidth'
import Video from './MediaBlockVideo'

const MediaBlock = ({ type, ...props }) => (
  <>
    {/* Conditional Rendering with enums */}
    {{
      gallery: <Gallery {...props} />,
      oneUp: <OneUp {...props} />,
      fullWidth: <FullWidth {...props} />,
      video: <Video {...props} />
    }[type]}
  </>
)

export default MediaBlock
