import React from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'

import ArrowLink from 'ArrowLink'

const ProjectNavigation = ({ previousProject, nextProject }) => (
  <nav className='c-project-nav'>
    <ul className={classNames('c-project-nav-menu u-mar-b-huge o-cleanlist', { 'c-project-nav-menu--single': nextProject && !previousProject })}>
      {
        previousProject &&
          <li className='c-project-nav-menu-item'>
            <ArrowLink
              to={previousProject.fields.slug}
              className='c-project-nav-menu-item__link'
              svgClassname='u-dis-b'
              state={{ reveal: true }}
              reverse
            >
              {previousProject.frontmatter.projectTitle}
            </ArrowLink>
          </li>
      }
      {
        nextProject &&
          <li className='c-project-nav-menu-item'>
            <ArrowLink
              to={nextProject.fields.slug}
              className='c-project-nav-menu-item__link'
              svgClassname='u-dis-b'
              state={{ reveal: true }}
            >
              {nextProject.frontmatter.projectTitle}
            </ArrowLink>
          </li>
      }
    </ul>
  </nav>
)

export default ProjectNavigation

export const query = graphql`
  fragment AllLiveProjectsFragment on Query {
    allProjects: allMarkdownRemark(
      filter: {
        frontmatter: {
          draft: {
            eq: false
          }
          templateKey: {
            eq: "templates/ProjectTemplate"
          }
          meta: {
            comingSoon: {
              ne: true
            }
          }
        }
      }
      sort: {
        fields: [frontmatter___meta___homepageOrder]
        order: ASC
      }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          projectTitle
          meta {
            comingSoon
          }
        }
      }
    }
  }
`
