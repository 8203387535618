import React from 'react'
import classNames from 'classnames'

import Reveal from 'Reveal'

import MediaBlockGalleryImage from './MediaBlockGalleryImage'

const MediaBlockFullWidth = ({ image, mobileImage, url, iframe, isGIF, lazyload, hideOnMobile }) => (
  <div className={classNames('c-media-block c-media-block--full-width', {
    'u-hide-completely-until@md': hideOnMobile
  })}
  >
    <Reveal fraction={0.25} duration={600}>
      <div>
        <MediaBlockGalleryImage
          image={image}
          mobileImage={mobileImage}
          url={url}
          iframe={iframe}
          isGIF={isGIF}
          lazyload={lazyload}
          sizes={{
            default: '100vw'
          }}
        />
      </div>
    </Reveal>
  </div>
)

export default MediaBlockFullWidth
