import React from 'react'

import Reveal from 'Reveal'

const ProjectCredits = ({ credits }) => (
  <div className='c-project-credits'>
    <div className='o-container'>
      <Reveal bottom>
        <h2 className='u-mar-b-huge'>Project credits</h2>
      </Reveal>
      <ul className='c-project-credits-list o-cleanlist'>
        {
          credits.map(({ name, role }, index) =>
            <Reveal key={`${index}_${name}`} bottom>
              <li className='c-project-credits-list-item u-mar-b u-mar-b-large@md'>
                <span className='u-dis-b'>{name}</span>
                <span className='u-dis-b u-color-grey'>{role}</span>
              </li>
            </Reveal>
          )
        }
      </ul>
    </div>
  </div>
)

export default ProjectCredits
