import React from 'react'

import { HTMLContent } from 'Content'
import Reveal from 'Reveal'

import ProjectHeader from './ProjectHeader'

const ProjectBody = ({ title, content }) => (
  <div className='c-project-content'>
    <div className='o-container--xxs'>
      <Reveal ssrReveal bottom>
        <ProjectHeader
          title={title}
        />
        <HTMLContent
          content={content}
          className='c-project-content__body'
        />
      </Reveal>
    </div>
  </div>
)

export default ProjectBody
